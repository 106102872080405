import { defineComponent } from 'vue';
import { Animation } from '@/Animation';
import './CombinationSearch.scss';
import { GameType } from '@/types/rooms';
export default defineComponent({
    name: 'CombinationSearch',
    props: {
        roomid: {
            type: String,
            required: true
        }
    },
    data: () => ({
        animation: Animation,
        counter: 0
    }),
    computed: {
        status() {
            const status = this.$store.direct.state.rooms.status[this.roomid];
            if (status && status.type === GameType.Cluedo) {
                return status;
            }
            return null;
        },
        clockRunning() {
            return this.$store.direct.state.rooms.clockRunning;
        }
    },
    watch: {
        clockRunning(newValue) {
            if (newValue) {
                this.counter++;
            }
        }
    }
});
