export default {
    name: 'FullscreenMessage',
    props: {
        message: {
            type: String,
            required: true
        },
        image: {
            type: String,
            default: '',
            required: false
        }
    }
};
