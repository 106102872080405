import { Timer } from '@/components/Widgets';
import { defineComponent } from 'vue';
import './IntroductionSlide.scss';
import { BeatoutLanguage } from '@/types/translations';
import useTranslations from '@/compositions/useTranslations';
export default defineComponent({
    name: "IntroductionSlide",
    components: {
        Timer
    },
    props: {
        roomid: {
            type: String,
            required: true
        }
    },
    setup: () => ({
        ...useTranslations()
    }),
    data: () => ({
        fadeOutTime: 5
    }),
    computed: {
        status() {
            return this.$store.direct.state.rooms.status[this.roomid];
        },
        config() {
            return this.$store.direct.state.rooms.config[this.roomid];
        },
        slide() {
            if (this.status === undefined)
                return null;
            return this.status.introduction;
        },
        timeleft() {
            var _a;
            if (this.status === undefined)
                return 0;
            return (_a = this.status.timeleft) !== null && _a !== void 0 ? _a : 0;
        },
        numslides() {
            if (this.config === undefined)
                return 0;
            return this.config.introduction.length;
        },
        currentSlide() {
            if (this.slide === null)
                return 0;
            return this.slide.current_slide;
        },
        text() {
            var _a, _b;
            if (this.slide === null)
                return '';
            const language = (_b = (_a = this.$store.direct.state.rooms.playingTeam) === null || _a === void 0 ? void 0 : _a.language) !== null && _b !== void 0 ? _b : BeatoutLanguage.Dutch;
            return this.slide.translations[language];
        },
        slideTimer() {
            if (this.slide === null)
                return 0;
            return this.slide.timer;
        },
        images() {
            if (this.slide === null)
                return [];
            return this.slide.images;
        }
    },
    watch: {
        timeleft(newValue) {
            if (this.currentSlide + 1 == this.numslides && newValue <= this.fadeOutTime) {
                const audioVolume = newValue / this.fadeOutTime;
                const backgroundAudio = this.$refs.backgroundAudio;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                backgroundAudio.volume = audioVolume;
            }
        }
    },
    mounted() {
        this.$store.direct.dispatch.rooms.subscribe(this.roomid);
    },
    beforeDestroy() {
        this.$store.direct.dispatch.rooms.unsubscribe(this.roomid);
    }
});
