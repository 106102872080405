import { defineComponent } from 'vue';
let animations = [];
export default defineComponent({
    name: 'SlideAnimation',
    props: {
        animationDuration: {
            type: Number
        }
    },
    created() {
        animations = [
            { name: 'left', isTaken: false },
            { name: 'right', isTaken: false },
            { name: 'top', isTaken: false },
            { name: 'bottom', isTaken: false }
        ];
    },
    methods: {
        getRandomElement(array) {
            const element = array[Math.floor(Math.random() * array.length)];
            if (element === undefined)
                throw new Error(`SlideAnimation: getRandomElement: cannot find element`);
            return element;
        },
        getAnimation(element) {
            const freeAnimations = animations.filter(item => !item.isTaken);
            const animation = freeAnimations.length > 0 ? this.getRandomElement(freeAnimations) :
                this.getRandomElement(animations);
            animation.isTaken = true;
            element.classList.add(`vc-slideshow-slide-${animation.name}-enter`);
            element.dataset.animation = animation.name;
            return animation.name;
        },
        beforeEnterEl(element) {
            this.getAnimation(element);
        },
        enterEl(element, done) {
            setTimeout(() => {
                const animationName = element.getAttribute('data-animation');
                element.classList.remove(`vc-slideshow-slide-${animationName}-enter`);
                done();
            }, 100);
        },
        leaveEl(element, done) {
            //exit animation
            const animationName = element.getAttribute('data-animation');
            element.classList.add(`vc-slideshow-slide-${animationName}-leave-active`);
            setTimeout(() => {
                done();
            }, this.animationDuration);
        },
    }
});
