import { defineComponent } from 'vue';
import '@/sass/transitions.scss';
import '../RoomWidgets.scss';
export default defineComponent({
    name: 'Level',
    data: () => ({
        visible: false
    }),
    computed: {
        level() {
            return this.$store.direct.state.rooms.level + 1;
        }
    },
    mounted() {
        setTimeout(() => { this.visible = true; }, 1000);
    }
});
