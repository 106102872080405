import { defineComponent } from 'vue';
// @ts-ignore
import VueCircle from 'vue3-circle-progress';
import './RoomDuration.scss';
export default defineComponent({
    name: 'RoomDuration',
    components: {
        VueCircle
    },
    data: () => ({
        fill: {
            gradient: "#b51f24"
        }
    }),
    computed: {
        timer() {
            return this.$store.direct.state.rooms.timer;
        },
        progress() {
            return Math.floor(this.timer / (18 * 60) * 1000) / 10;
        },
        timeleft() {
            let secondsleft = Math.floor(this.timer);
            const minutesleft = Math.floor(secondsleft / 60);
            secondsleft %= 60;
            return ('00' + minutesleft).slice(-2) + ':' + ('00' + secondsleft).slice(-2);
        }
    }
});
