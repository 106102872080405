// @ts-ignore
import { TweenMax, Elastic, TimelineMax, Expo, TweenLite } from 'gsap';
export const Animation = {
    animateRFIDScan() {
        TweenMax.from('.badgeicon', 1, { opacity: 0.2 });
    },
    animateAvatarShow(element, done) {
        element.style.width = '0px';
        element.style.opacity = '0';
        const width = element.style.width;
        element.style.width = '0px';
        setTimeout(() => {
            element.style.opacity = '1';
            element.style.width = width;
            TweenMax.from(element, 2, {
                opacity: 0,
                bottom: 400,
                onComplete: done,
                ease: Elastic.easeOut.config(1, 0.3)
            });
        }, 1200 + (Math.random() * 500));
    },
    animateAvatarHide(element, done) {
        TweenMax.to(element, 0.25, { opacity: 0, top: '200px', onComplete: done });
    },
    animateLevelChange(done) {
        const body = document.querySelectorAll('body')[0];
        if (body === undefined)
            return;
        body.style.overflow = 'hidden';
        const tl = new TimelineMax({
            onComplete: done
        });
        tl.to('#levelChangePage', 1.5, {
            height: '100vh',
            top: 0,
            ease: Expo.easeOut
        }, 1);
        tl.to('#levelChangePage', 1.5, {
            height: '0',
            top: '100vh',
            ease: Expo.easeIn
        });
        setTimeout(() => {
            new Audio(require('../src/assets/sound/levelup.mp3')).play();
        }, 1000);
    },
    animateFullscreenMessage(_element, done) {
        const body = document.querySelectorAll('body')[0];
        if (body === undefined)
            return;
        body.style.overflow = 'hidden';
        const tl = new TimelineMax({
            onComplete: done
        });
        tl.to('.fullscreenMessage', 1.5, {
            height: '45vh',
            ease: Expo.easeOut
        }, 1);
    },
    animateRemainingTime(element, done) {
        const tl = new TimelineMax({
            onComplete: done
        });
        tl.to(element, 2.5, {
            rotation: 30,
            ease: Elastic.easeOut.config(1.75, 0.2)
        }, 1);
        tl.to(element, 2.5, {
            rotation: 0,
            ease: Elastic.easeOut.config(1.75, 0.2)
        }, 2);
    },
    animateLevel(element, done) {
        const tl = new TimelineMax({
            onComplete: done
        });
        tl.to(element, 2.5, {
            scaleX: 1.1,
            scaleY: 1.1,
            ease: Elastic.easeOut.config(1.75, 0.2)
        }, 1);
        tl.to(element, 2.5, {
            scaleX: 1,
            scaleY: 1,
            ease: Elastic.easeOut.config(1.75, 0.2)
        }, 2);
    },
    animateMessageBar(element, done) {
        const tl = new TimelineMax({
            onComplete: done
        });
        tl.to(element, 0.5, {
            scaleX: 3,
            scaleY: 3,
            y: "2.5vh",
            ease: "bounce.out"
        }, 1);
        tl.to(element, 0.5, {
            scaleX: 1,
            scaleY: 1,
            y: 0,
            ease: "bounce.in"
        }, 2);
    },
    animateBirdFlying(element, done) {
        console.log('Bird fly animation: ' + element);
        const tl = new TimelineMax({
            onComplete: done
        });
        tl.to(element, 0.005, {
            scaleX: 4.5,
            scaleY: 4.5,
            opacity: 0,
            x: "100vw"
        }, 1);
        tl.to(element, 1, {
            scaleX: 1,
            scaleY: 1,
            x: 0,
            opacity: 1,
            ease: "bounce.in"
        }, 2);
    },
    animateBirdRemove(element, done) {
        done();
        console.log('Bird fly remove animation: ' + element);
    },
    animateCodeEnter(_element, done) {
        console.log('Keyboard key entered: ' + _element.getAttribute('char'));
        if (_element.getAttribute('char') == '_') {
            TweenLite.to(_element, 0.05, { opacity: 1 });
            done();
        }
        else {
            TweenLite.to(_element, 0.25, { opacity: 1, scale: 1.25, marginLeft: '0.25em', width: '22%' });
            TweenLite.to(_element, 0.5, { scale: 1, delay: 0.5, marginLeft: 0, width: '25%' });
            done();
        }
    }
};
