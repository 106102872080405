import store from "@/store";
import { computed } from "vue";
const useLocation = () => {
    const currentLocation = computed(() => store.getters.location.currentLocation);
    const currentLocationId = computed(() => store.state.location.locationId);
    return {
        currentLocationId,
        currentLocation,
        updateLocationId: (id) => store.dispatch.location.changeLocation(id)
    };
};
export default useLocation;
