import Hls from 'hls.js';
import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        url: {
            required: true,
            type: String
        }
    },
    data: () => ({
        hls: new Hls()
    }),
    mounted() {
        const video = this.$refs.video;
        this.hls.loadSource(this.url);
        this.hls.attachMedia(video);
        this.hls.on(Hls.Events.MANIFEST_PARSED, function () {
            video.play();
        });
    },
    beforeUnmount() {
        const video = this.$refs.video;
        this.hls.destroy();
        video.pause();
    }
});
