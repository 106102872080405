import { defineComponent } from 'vue';
import SlideAnimation from "../SlideAnimation.vue";
import useSimpleSlide from '@/compositions/useSimpleSlide';
export default defineComponent({
    //a slide with one or two images
    name: 'SimpleSlide',
    components: {
        SlideAnimation
    },
    setup: () => ({
        ...useSimpleSlide()
    })
});
