import { defineComponent } from 'vue';
import SimpleSlide from "./SimpleSlide.vue";
export default defineComponent({
    name: 'FiveImagesSlide',
    mixins: [SimpleSlide],
    computed: {
        slideClass() {
            // @ts-ignore
            let rndInt = this.getRandomInt(1, 3);
            //the best template for the case when there is only one vertical image
            //do not use other templates if there are only a few horizontal images
            // @ts-ignore
            if (this.verticalImages.length == 1 || this.horizontalImages.length < 3) {
                rndInt = 1;
            }
            //do not use template 1 if there are no vertical images
            // @ts-ignore
            if (this.verticalImages.length === 0)
                // @ts-ignore
                rndInt = this.getRandomInt(2, 3);
            if (rndInt === 1) {
                this.swapMiddleImageWithVertical();
            }
            else {
                // @ts-ignore
                this.replaceVerticalsInPositions([1, 2, 4]);
            }
            return `${rndInt}`;
        },
        leftColumn() {
            // @ts-ignore
            return this.slideClass == 1 ? this.images.slice(0, 2) : this.images.slice(0, 1);
        },
        centerImg() {
            // @ts-ignore
            return this.slideClass == 1 && this.images[2] !== null ? this.images[2] : null;
        },
        rightColumn() {
            // @ts-ignore
            return this.slideClass == 1 ? this.images.slice(3) : this.images.slice(1);
        }
    },
    methods: {
        swapMiddleImageWithVertical() {
            // @ts-ignore
            const verticalImageIdx = this.images.findIndex(img => img.isVertical);
            // @ts-ignore
            if (verticalImageIdx >= 0 && this.images[2] !== null)
                // @ts-ignore
                [this.images[2], this.images[verticalImageIdx]] = [this.images[verticalImageIdx], this.images[2]];
        }
    }
});
