import useSimpleSlide from '@/compositions/useSimpleSlide';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'TripleSlide',
    setup: () => ({
        ...useSimpleSlide()
    }),
    computed: {
        slideClass() {
            //for three vertical images in the row
            // @ts-ignore
            if (this.verticalImages.length == 3) {
                return `vc-slideshow-is-triple-5`;
            }
            // if a slide has the only one vertical img - use templates that doesn't use 3/4 of a slide width for it
            // @ts-ignore
            if (this.verticalImages.length == 1) {
                // @ts-ignore
                this.setFirstVertical(this.images);
                // @ts-ignore
                return `vc-slideshow-is-triple-${this.getRandomInt(1, 2)}`;
            }
            // @ts-ignore
            const rndType = this.getRandomInt(1, 4);
            // use horizontal image for the 3/4 of a slide width
            if (rndType >= 3)
                // @ts-ignore
                this.setFirstHorizontal(this.images);
            return `vc-slideshow-is-triple-${rndType}`;
        },
        leftColumn() {
            // @ts-ignore
            return this.images.slice(0, 1);
        },
        rightColumn() {
            // @ts-ignore
            return this.images.slice(1);
        }
    }
});
