import useSimpleSlide from '@/compositions/useSimpleSlide';
import { defineComponent } from "@vue/runtime-core";
export default defineComponent({
    name: 'FourImagesSlide',
    setup: () => ({
        ...useSimpleSlide()
    }),
    computed: {
        slideClass() {
            if (this.verticalImages.length > 0) {
                this.setFirstVertical(this.images);
                this.replaceVerticalsInPositions([1]);
                return `${this.getRandomInt(4, 5)}`;
            }
            return `${this.getRandomInt(1, 5)}`;
        },
        leftColumn() {
            return this.slideClass === '1' ? this.images.slice(0, 2) : this.images.slice(0, 1);
        },
        rightColumn() {
            return this.slideClass === '1' ? this.images.slice(2) : this.images.slice(1);
        }
    }
});
