import { defineComponent } from 'vue';
import Collage from '@/components/Widgets/Collage';
export default defineComponent({
    name: 'Instagram',
    components: {
        Collage
    },
    props: {
        teamId: {
            type: String,
            required: false
        },
        view: {
            type: String,
            default: 'list'
        }
    },
    computed: {
        posts() {
            const posts = this.$store.direct.state.instagram.posts;
            if (this.teamId === 'all') {
                return posts;
            }
            else if (this.teamId === 'onlyteams') {
                return Object.fromEntries(Object.entries(posts).filter(([_k, v]) => v.team_id));
            }
            // @ts-ignore
            return Object.fromEntries(Object.entries(posts).filter(([_k, v]) => v.team_id === Number.parseInt(this.teamId)));
        },
        teams() {
            return this.$store.direct.state.teams.active;
        },
        images() {
            const imageUrls = [];
            for (const post of Object.values(this.posts)) {
                imageUrls.push({ image: post.image });
            }
            return imageUrls;
        }
    },
    watch: {
        teamId(newValue, oldValue) {
            this.$store.direct.dispatch.instagram.unsubscribe(oldValue);
            this.$store.direct.dispatch.instagram.subscribe(newValue);
        }
    },
    mounted() {
        this.$store.direct.dispatch.instagram.subscribe(this.teamId);
    },
    beforeDestroy() {
        this.$store.direct.dispatch.instagram.unsubscribe(this.teamId);
    },
    methods: {
        shortText(text) {
            const maxLength = 140;
            if (text.length < maxLength) {
                return text;
            }
            return text.slice(0, Math.max(0, maxLength)) + '...';
        },
        timestampToString(timestamp) {
            const date = new Date(timestamp * 1000);
            return date.toString();
        },
        getTeamName(teamId) {
            if (teamId) {
                const team = this.teams[teamId];
                return team ? team.name : `Non active team (${teamId})`;
            }
            return '---';
        }
    }
});
