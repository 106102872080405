import { defineComponent } from 'vue';
import useTranslations from '@/compositions/useTranslations';
export default defineComponent({
    name: 'ButtonPressBox',
    props: {
        imgheight: {
            type: String,
            required: false,
            default: '100%'
        }
    },
    setup: () => ({
        ...useTranslations()
    })
});
