import { defineComponent } from 'vue';
import { SimpleSlide, TripleSlide, FourImagesSlide, FiveImagesSlide } from './slides';
// import LoadingSpinner from './LoadingSpinner.vue'
import './assets/main.scss';
import './assets/slides.scss';
import './assets/animation.scss';
export default defineComponent({
    name: 'VueCollageSlideshow',
    components: {
        SimpleSlide,
        TripleSlide,
        FourImagesSlide,
        FiveImagesSlide
    },
    props: {
        images: {
            type: Array,
            required: true
        },
        height: {
            type: String,
            default: '600px'
        },
        slidesInterval: {
            type: Number,
            default: 4000
        },
        collageSizeMin: {
            type: Number,
            default: 2
        },
        collageSizeMax: {
            type: Number,
            default: 5
        },
        noImagesMsg: {
            type: String,
            default: 'No Images',
        },
        showNoImagesMsg: {
            type: Boolean,
            default: true,
        },
        showLoadingMsg: {
            type: Boolean,
            default: true,
        },
        loadingMsg: {
            type: String,
            default: 'Loading...',
        },
        keyboardNavigation: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        slides: [],
        isLoading: false,
        status: 0,
        slidesTimeout: null,
        activeSlideIdx: 0,
        animationDuration: 500,
        animationTimeout: null
    }),
    computed: {
        noImages() {
            return !this.images || this.images.length <= 0;
        }
    },
    watch: {
        images() {
            this.cacheImages();
        }
    },
    created() {
        this.cacheImages();
    },
    methods: {
        async cacheImages() {
            if (this.noImages)
                return;
            this.isLoading = true;
            const values = await this.loadImages(this.images);
            this.createCollages(values.filter(item => !item.is_error));
            this.isLoading = false;
            this.play();
            if (this.keyboardNavigation)
                window.addEventListener('keyup', this.pressKey);
        },
        slideTemplate(count) {
            switch (count) {
                case 3:
                    return 'TripleSlide';
                case 4:
                    return 'FourImagesSlide';
                case 5:
                    return 'FiveImagesSlide';
                default:
                    return 'SimpleSlide';
            }
        },
        pressKey(e) {
            switch (e.keyCode) {
                case 32:
                    this.onKeySpace();
                    break;
                case 37:
                    this.onKeyLeft();
                    break;
                case 39:
                    this.onKeyRight();
                    break;
            }
        },
        onKeySpace() {
            switch (this.status) {
                case 1:
                case 3:
                    this.pause();
                    break;
                case 2:
                    this.resume();
                    break;
                default:
                    this.play();
            }
        },
        onKeyLeft() {
            if (this.animationTimeout !== null)
                window.clearTimeout(this.animationTimeout);
            this.animationTimeout = window.setTimeout(this.previousSlide, this.animationDuration);
        },
        onKeyRight() {
            if (this.animationTimeout !== null)
                window.clearTimeout(this.animationTimeout);
            this.animationTimeout = window.setTimeout(this.nextSlide, this.animationDuration);
        },
        pause() {
            if (this.slidesTimeout !== null) {
                clearTimeout(this.slidesTimeout);
                this.slidesTimeout = null;
            }
            this.status = 2;
        },
        resume() {
            this.status = 3;
            if (this.animationTimeout !== null)
                window.clearTimeout(this.animationTimeout);
            this.animationTimeout = window.setTimeout(this.nextSlide, this.animationDuration);
        },
        play() {
            if (this.slides.length <= 0)
                return;
            this.startSlidesTimeout();
        },
        nextSlide() {
            this.activeSlideIdx++;
            if (this.activeSlideIdx >= this.slides.length) {
                this.activeSlideIdx = 0;
            }
            if (this.status !== 2)
                this.startSlidesTimeout();
        },
        previousSlide() {
            this.activeSlideIdx--;
            if (this.activeSlideIdx < 0) {
                this.activeSlideIdx = this.slides.length - 1;
            }
            if (this.status !== 2)
                this.startSlidesTimeout();
        },
        startSlidesTimeout() {
            this.status = 1;
            if (this.slidesTimeout !== null)
                window.clearTimeout(this.slidesTimeout);
            this.slidesTimeout = window.setTimeout(() => {
                this.nextSlide();
            }, this.slidesInterval);
        },
        loadImage(source) {
            return new Promise(function (resolve) {
                const img = new Image();
                img.addEventListener('load', () => {
                    const isHorizontal = img.width >= img.height;
                    resolve({
                        image: img.src,
                        isHorizontal,
                        isVertical: !isHorizontal,
                        is_error: false
                    });
                });
                img.addEventListener('error', function () {
                    resolve({
                        image: img.src,
                        is_error: true
                    });
                });
                img.src = source;
            });
        },
        loadImages(images) {
            const promises = [];
            images.forEach(item => {
                promises.push(this.loadImage(item.image));
            });
            return Promise.all(promises);
        },
        getRandomInt(min, max) {
            let min_value = Math.min(min, max);
            let max_value = Math.max(min, max);
            min_value = Math.min(Math.max(Math.ceil(min_value), 1), 5);
            max_value = Math.min(Math.max(Math.floor(max_value), 1), 5);
            return Math.floor(Math.random() * (max_value - min_value + 1)) + min_value;
        },
        createCollages(images) {
            let index = 0;
            let size = 1;
            while (index < images.length) {
                size = this.getRandomInt(this.collageSizeMin, this.collageSizeMax);
                const slide = images.slice(index, size + index);
                // @ts-ignore
                this.slides.push(slide);
                index = size + index;
            }
        },
        add(images) {
            this.loadImages(images).then(values => {
                this.createCollages(values.filter(item => !item.is_error));
            })
                .catch(() => {
            });
        }
    }
});
