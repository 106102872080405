import { defineComponent } from 'vue';
import { ModalBox } from '@/components/UI';
import HLSPlayer from './HlsPlayer.vue';
export default defineComponent({
    name: 'VideoPlayer',
    components: {
        ModalBox,
        HLSPlayer
    },
    props: {
        url: {
            required: true,
            type: String
        },
        popupurl: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            required: false,
            default: null
        }
    },
    data: () => ({
        popup: false
    }),
    mounted() {
        this.onResize();
        window.addEventListener('resize', this.onResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize() {
            const player = this.$refs.player;
            if (player && player.clientWidth !== undefined && player !== undefined) {
                player.style.height = (player.clientWidth * 9 / 16) + 'px';
            }
        }
    }
});
