import { computed, defineProps, onMounted, ref, watch } from 'vue';
const setFirstVertical = (slide) => {
    slide.sort((x, y) => x.isVertical ? -1 : (y.isVertical ? 1 : 0));
};
const setFirstHorizontal = (slide) => {
    slide.sort((x, y) => x.isHorizontal ? -1 : (y.isHorizontal ? 1 : 0));
};
const getRandomInt = (min, max) => {
    const new_min = Math.ceil(min);
    const new_max = Math.floor(max);
    return Math.floor(Math.random() * (new_max - new_min + 1)) + new_min;
};
/* eslint-disable max-lines-per-function */
const useSimpleSlide = () => {
    const props = defineProps({
        images: {
            type: Array,
            required: true
        },
        slidesInterval: {
            type: Number,
            default: 0
        },
        status: {
            type: Number,
            default: 0
        },
        animationDuration: {
            type: Number,
            default: 0
        }
    });
    const showImages = ref(false);
    const startLeaveTimeout = ref(null);
    const playLeaveTimeout = ref(0);
    const leaveStartTime = computed(() => props.slidesInterval - props.animationDuration + 100);
    const horizontalImages = computed(() => props.images.filter(item => item.isHorizontal));
    const verticalImages = computed(() => props.images.filter(item => item.isVertical));
    const slideClass = computed(() => {
        switch (props.images.length) {
            case 1:
                return 'vc-slideshow-is-single';
            //vertical image is always goes first (in order to contain maximum 50%, usually 30%, of the screen width)
            case 2:
                if (verticalImages.value.length > 0) {
                    setFirstVertical(props.images);
                    return `vc-slideshow-is-double vc-slideshow-is-double-${getRandomInt(1, 3)}`;
                }
                else { //use 50|50 template if there are no vertical images
                    return `vc-slideshow-is-double vc-slideshow-is-double-1`;
                }
        }
        return '';
    });
    const playLeave = (time) => {
        if (startLeaveTimeout.value !== null)
            clearTimeout(startLeaveTimeout.value);
        startLeaveTimeout.value = setTimeout(() => {
            showImages.value = false;
        }, time);
    };
    watch(() => props.status, newValue => {
        //if paused - stop animation timeout
        if (newValue === 2 && startLeaveTimeout.value !== null)
            clearTimeout(startLeaveTimeout.value);
        //if resumed - play leave animation immediately
        if (newValue === 3) {
            playLeave(0);
        }
    });
    onMounted(() => {
        setTimeout(() => {
            showImages.value = true;
        }, 10);
        playLeave(leaveStartTime.value);
    });
    const replaceVerticalsInPositions = (neededIdxs) => {
        if (!neededIdxs || neededIdxs.length === 0 || horizontalImages.value.length === 0)
            return;
        neededIdxs.forEach(idx => {
            const image = props.images[idx];
            if (image === null || image === void 0 ? void 0 : image.isVertical) {
                //find indexes of horizontal images
                let horizontalIdxs = horizontalImages.value.map(item => props.images.indexOf(item));
                //remove indexes which already are in appropriate places
                horizontalIdxs = horizontalIdxs.filter((element) => !neededIdxs.includes(element));
                //swap any horizontal image with founded vertical
                const horizontalIdx = horizontalIdxs[0];
                if (horizontalIdx !== null && horizontalIdx !== undefined) {
                    // @ts-ignore
                    [this.images[idx], this.images[horizontalIdx]] = [this.images[horizontalIdx], this.images[idx]];
                }
            }
        });
    };
    return {
        ...props,
        showImages,
        startLeaveTimeout,
        playLeaveTimeout,
        leaveStartTime,
        horizontalImages,
        verticalImages,
        slideClass,
        setFirstVertical,
        setFirstHorizontal,
        getRandomInt,
        replaceVerticalsInPositions
    };
};
export default useSimpleSlide;
