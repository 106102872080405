import { defineComponent } from 'vue';
import { Animation } from '@/Animation';
export default defineComponent({
    name: 'CodeInput',
    props: {
        code: {
            type: String,
            required: true
        },
        length: {
            type: Number,
            required: true
        },
        message: {
            type: String,
            required: true
        }
    },
    data: () => ({
        animation: Animation
    }),
    computed: {
        charList() {
            const chars = [];
            for (let index = 0; index < this.length; ++index) {
                const char = this.code[index];
                if (char !== undefined) {
                    chars.push(char);
                }
                else {
                    chars.push('_');
                }
            }
            return chars;
        }
    },
    methods: {
        beforeEnter(element) {
            element.style.opacity = '0';
        },
    }
});
