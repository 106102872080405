import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        timeout: {
            type: Number,
            default: 1000
        }
    },
    data: () => ({
        interval: null
    }),
    watch: {
        timeout() {
            this.stop();
            this.start();
        }
    },
    mounted() {
        this.update();
        this.start();
    },
    beforeDestroy() {
        this.stop();
    },
    methods: {
        start() {
            this.interval = window.setInterval(() => {
                this.update();
            }, this.timeout);
        },
        stop() {
            if (this.interval) {
                window.clearInterval(this.interval);
            }
        },
        update() {
            this.$emit('onInterval');
        }
    },
    render: () => null
});
