import { defineComponent } from 'vue';
export default defineComponent({
    name: 'InfoBar',
    props: {
        message: {
            type: String,
            required: false
        }
    }
});
