import { defineComponent } from 'vue';
import { Animation } from '@/Animation';
import './Combination.scss';
export default defineComponent({
    name: 'Combination',
    data: () => ({
        animation: Animation
    }),
    computed: {
        availableButtons() {
            return this.$store.direct.state.rooms.availableButtons;
        },
        numHidden() {
            return this.$store.direct.state.rooms.numHidden;
        },
        numBlurred() {
            return this.$store.direct.state.rooms.numBlurred;
        },
        combination() {
            return this.$store.direct.state.rooms.currentCombination;
        },
        selections() {
            return this.$store.direct.state.rooms.currentSelection;
        },
        wrongPersons() {
            const correctPersons = [];
            this.combination.forEach(person => {
                correctPersons.push(person.id);
            });
            return this.selections.filter(person => !correctPersons.includes(person.id));
        }
    },
    methods: {
        isVisible(index) {
            return index >= this.numHidden;
        },
        isBlurred(index) {
            return index < this.numHidden + this.numBlurred;
        },
        isCorrect(id) {
            return this.selections.find(person => person.id === id) ? true : false;
        }
    }
});
