import { defineComponent } from 'vue';
import Interval from '@/components/General/Interval';
import { Animation } from '@/Animation';
import '@/sass/transitions.scss';
import '../RoomWidgets.scss';
export default defineComponent({
    name: 'Timer',
    components: {
        Interval
    },
    props: {
        title: {
            type: String,
            default: 'Time Left'
        },
        time: {
            type: Number,
            required: false
        },
        display: {
            type: String,
            default: 'seconds'
        },
        audioTrack: {
            type: String,
            required: false,
            default: require('@/assets/sound/clocktick.mp3')
        },
        volume: {
            type: Number,
            required: false,
            default: 0.2
        }
    },
    data: () => ({
        visible: false
    }),
    computed: {
        timeleft() {
            if (this.time) {
                return this.time;
            }
            return this.$store.direct.state.rooms.timer;
        },
        clockrunning() {
            return this.$store.direct.state.rooms.clockRunning;
        }
    },
    mounted() {
        setTimeout(() => { this.visible = true; }, 1000);
    },
    methods: {
        updateVolume() {
            // @ts-ignore
            this.$refs.audio.volume = this.volume;
        },
        animate() {
            Animation.animateRemainingTime('.timer_icon', () => { });
        },
        printTimeLeft() {
            let secondsleft = Math.floor(this.timeleft);
            const minutesleft = Math.floor(secondsleft / 60);
            secondsleft %= 60;
            return ('00' + minutesleft).slice(-2) + ':' + ('00' + secondsleft).slice(-2);
        }
    }
});
