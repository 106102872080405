import { defineComponent } from 'vue';
import { Progress } from '@/components/UI';
export default defineComponent({
    name: 'MistakesLeft',
    components: {
        Progress
    },
    computed: {
        percentage() {
            return this.$store.direct.state.rooms.score;
        }
    }
});
