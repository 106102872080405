import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Webcam',
    props: {
        width: {
            type: [Number, String],
            default: '100%'
        },
        height: {
            type: [Number, String],
            default: 500
        },
        autoplay: {
            type: Boolean,
            default: true
        },
        screenshotFormat: {
            type: String,
            default: 'image/jpeg'
        },
        deviceId: {
            type: String,
            default: null
        },
        playsinline: {
            type: Boolean,
            default: true
        },
        resolution: {
            type: Object,
            default: null
        }
    },
    data: () => ({
        source: null,
        ctx: null,
        canvas: null,
        camerasListEmitted: false,
        cameras: []
    }),
    watch: {
        deviceId(id) {
            this.changeCamera(id);
        }
    },
    mounted() {
        this.setupMedia();
    },
    methods: {
        legacyGetUserMediaSupport() {
            return (constraints) => {
                // First get ahold of the legacy getUserMedia, if present
                const getUserMedia = 
                // @ts-ignore
                navigator.getUserMedia ||
                    // @ts-ignore
                    navigator.webkitGetUserMedia ||
                    // @ts-ignore
                    navigator.mozGetUserMedia ||
                    // @ts-ignore
                    navigator.msGetUserMedia ||
                    // @ts-ignore
                    navigator.oGetUserMedia;
                // Some browsers just don't implement it - return a rejected promise with an error
                // to keep a consistent interface
                if (!getUserMedia) {
                    return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
                }
                // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
                return new Promise((resolve, reject) => {
                    getUserMedia.call(navigator, constraints, resolve, reject);
                });
            };
        },
        setupMedia() {
            if (navigator.mediaDevices === undefined) {
                // @ts-ignore
                navigator.mediaDevices = {};
            }
            if (navigator.mediaDevices.getUserMedia === undefined) {
                // @ts-ignore
                navigator.mediaDevices.getUserMedia = this.legacyGetUserMediaSupport();
            }
            this.testMediaAccess();
        },
        loadCameras() {
            navigator.mediaDevices
                .enumerateDevices()
                .then(deviceInfos => {
                for (let i = 0; i !== deviceInfos.length; ++i) {
                    const deviceInfo = deviceInfos[i];
                    if ((deviceInfo === null || deviceInfo === void 0 ? void 0 : deviceInfo.kind) === 'videoinput') {
                        this.cameras.push(deviceInfo);
                    }
                }
            })
                .then(() => {
                if (!this.camerasListEmitted) {
                    this.$emit('cameras', this.cameras);
                    this.camerasListEmitted = true;
                }
            })
                .catch(error => this.$emit('notsupported', error));
        },
        /**
         * change to a different camera stream, like front and back camera on phones
         */
        changeCamera(deviceId) {
            this.stop();
            this.$emit('camera-change', deviceId);
            this.loadCamera(deviceId);
        },
        /**
         * load the stream to the
         */
        loadSrcStream(stream) {
            const video = this.$refs.video;
            if (video === undefined)
                return;
            if ('srcObject' in video) {
                // new browsers api
                video.srcObject = stream;
            }
            else {
                // old broswers
                // @ts-ignore
                this.source = window.HTMLMediaElement.srcObject(stream);
            }
            // Emit video start/live event
            video.addEventListener('loadedmetadata', () => {
                this.$emit('video-live', stream);
            });
            this.$emit('started', stream);
        },
        /**
         * stop the selected streamed video to change camera
         */
        stopStreamedVideo(videoElement) {
            const stream = videoElement.srcObject;
            if (stream === null)
                return;
            const tracks = stream.getTracks();
            const video = this.$refs.video;
            if (video === undefined)
                return;
            tracks.forEach(track => {
                // stops the video track
                track.stop();
                this.$emit('stopped', stream);
                video.srcObject = null;
                this.source = null;
            });
        },
        // Stop the video
        stop() {
            const video = this.$refs.video;
            if (video === undefined)
                return;
            if (video.srcObject) {
                this.stopStreamedVideo(video);
            }
        },
        // Start the video
        start() {
            if (this.deviceId) {
                this.loadCamera(this.deviceId);
            }
        },
        /**
         * test access
         */
        testMediaAccess() {
            const constraints = { video: true };
            if (this.resolution) {
                // @ts-ignore
                constraints.video = {};
                // @ts-ignore
                constraints.video.height = this.resolution.height;
                // @ts-ignore
                constraints.video.width = this.resolution.width;
            }
            navigator.mediaDevices
                .getUserMedia(constraints)
                .then(() => this.loadCameras())
                .catch(error => this.$emit('error', error));
        },
        /**
         * load the Camera passed as index!
         */
        loadCamera(device) {
            const constraints = {
                video: {
                    deviceId: { exact: device },
                    width: undefined,
                    height: undefined
                }
            };
            if (this.resolution) {
                constraints.video.height = this.resolution.height;
                constraints.video.width = this.resolution.width;
            }
            navigator.mediaDevices
                .getUserMedia(constraints)
                .then(stream => this.loadSrcStream(stream))
                .catch(error => this.$emit('error', error));
        },
        capture() {
            const canvas = this.getCanvas();
            if (canvas !== null)
                return canvas.toDataURL(this.screenshotFormat);
            return '';
        },
        getCanvas() {
            const video = this.$refs.video;
            if (video === undefined)
                return null;
            if (!this.ctx) {
                const domcanvas = document.createElement('canvas');
                domcanvas.height = video.videoHeight;
                domcanvas.width = video.videoWidth;
                // @ts-ignore
                this.canvas = domcanvas;
                // @ts-ignore
                this.ctx = domcanvas.getContext('2d');
            }
            // @ts-ignore
            const { ctx, canvas } = this;
            if (canvas !== null && ctx !== null) {
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            }
            // @ts-ignore
            return canvas;
        }
    }
});
