import { defineComponent } from 'vue';
export default defineComponent({
    name: 'RoomJungleStatus',
    props: {
        status: {
            type: Object,
            required: true
        }
    },
    computed: {
        buttons() {
            const button_status = new Array(16).fill('off');
            this.status.buttons.forEach((button, index) => {
                button_status[index] = button;
            });
            return button_status;
        }
    },
    watch: {
        buttons: {
            handler(newVal, oldVal) {
                for (const index in newVal) {
                    if (newVal[index] !== oldVal[index]) {
                        new Audio(require('@/assets/sound/wilhelm.mp3')).play();
                        console.log('SCREAM');
                        return;
                    }
                }
            }
        }
    }
});
