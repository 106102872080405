import { defineComponent } from 'vue';
import axios from 'axios';
import useLocation from '@/compositions/useLocation';
export default defineComponent({
    name: 'WebrtcPlayer',
    props: {
        id: {
            required: true,
            type: String
        }
    },
    setup: () => ({
        ...useLocation()
    }),
    data: () => ({
        peerConnection: new RTCPeerConnection({
            iceServers: [{
                    urls: "stun:stun.l.google.com:19302",
                }]
        }),
        stream: new MediaStream(),
        interval: null
    }),
    async mounted() {
        this.restartStream();
        const videoElem = this.$refs.video;
        this.interval = setInterval(() => {
            videoElem.play();
            if (['failed', 'disconnected', 'closed'].includes(this.peerConnection.connectionState)) {
                console.log('Connection state indicates a problem, restarting stream...');
                this.restartStream();
            }
        }, 10000);
    },
    beforeUnmount() {
        const videoElem = this.$refs.video;
        videoElem.pause();
        this.stream.getTracks().forEach(track => {
            track.stop();
            this.stream.removeTrack(track);
        });
        // Close the peer connection and remove event listeners
        this.peerConnection.getSenders().forEach(sender => {
            if (sender.track) {
                sender.track.stop();
            }
            this.peerConnection.removeTrack(sender);
        });
        this.peerConnection.getReceivers().forEach(receiver => {
            if (receiver.track) {
                receiver.track.stop();
            }
        });
        this.peerConnection.ontrack = null;
        this.peerConnection.oniceconnectionstatechange = null;
        this.peerConnection.onnegotiationneeded = null;
        this.peerConnection.close();
        if (this.interval !== null) {
            clearInterval(this.interval);
        }
    },
    methods: {
        async getRemoteSdp() {
            const localDescription = this.peerConnection.localDescription;
            if (localDescription === null) {
                console.warn('localDescription is null');
                return;
            }
            const formData = new FormData();
            formData.append('suuid', this.id);
            formData.append('data', btoa(localDescription.sdp));
            const response = await axios.post(`https://${this.currentLocation.apiurl}/camera/stream/receiver/` + this.id, formData);
            const data = response.data;
            this.peerConnection.setRemoteDescription(new RTCSessionDescription({
                type: 'answer',
                sdp: atob(data)
            }));
        },
        // eslint-disable-next-line max-lines-per-function
        async restartStream() {
            const videoElem = this.$refs.video;
            this.peerConnection.ontrack = (event) => {
                const tracks = this.stream.getTracks();
                tracks.forEach(function (track) {
                    track.stop();
                });
                this.stream.addTrack(event.track);
                videoElem.srcObject = this.stream;
                event.track.addEventListener('ended', () => {
                    console.log('Track ended, checking stream status or restarting...');
                    this.restartStream();
                });
            };
            this.peerConnection.oniceconnectionstatechange = () => {
                if (['failed', 'disconnected', 'closed'].includes(this.peerConnection.iceConnectionState)) {
                    console.log('Connection state indicates a problem, restarting stream...');
                    this.restartStream();
                }
            };
            this.peerConnection.onnegotiationneeded = async () => {
                const offer = await this.peerConnection.createOffer();
                await this.peerConnection.setLocalDescription(offer);
                this.getRemoteSdp();
            };
            const response = await axios.get(`https://${this.currentLocation.apiurl}/camera/stream/codec/` + this.id);
            const data = response.data;
            data.forEach((value) => {
                this.peerConnection.addTransceiver(value.Type, {
                    'direction': 'sendrecv'
                });
            });
        }
    }
});
