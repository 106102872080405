import { defineComponent } from 'vue';
import FileUpload from 'vue-upload-component';
export default defineComponent({
    name: 'Uploader',
    components: {
        FileUpload
    },
    props: {
        target: {
            type: String,
            required: true
        },
        simple: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        files: [],
        updateSend: {
            filename: undefined
        }
    }),
    watch: {
        files: {
            handler(newValue, oldValue) {
                if (this.simple && (oldValue.files === undefined || newValue.files.id !== oldValue.files.id)) {
                    // @ts-ignore
                    this.$refs.upload.active = true;
                }
            },
            deep: true
        }
    },
    methods: {
        inputFilter(newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
                    return prevent();
                }
                if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
                    return prevent();
                }
            }
        },
        inputFile(newFile) {
            if (newFile && newFile.xhr) {
                //  Get the response status code
                const filename = newFile.file.name;
                if (this.updateSend.filename === undefined) {
                    this.$emit('onresponse', { code: newFile.xhr.status, filename });
                    if (newFile.xhr.status === 200) {
                        this.$store.direct.dispatch.files.get();
                        this.updateSend.filename = true;
                    }
                }
            }
        }
    }
});
