import { defineComponent } from 'vue';
import { Animation } from '@/Animation';
export default defineComponent({
    name: 'Messagebar',
    props: {
        message: {
            type: String,
            required: true
        },
        animate: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    watch: {
        message() {
            console.log('Animate: ' + this.animate);
            if (this.animate)
                Animation.animateMessageBar('.messagebar_intern', () => { });
        }
    }
});
