import { defineComponent } from 'vue';
// @ts-ignore
import Popper from 'vue3-popper';
import { Table } from '@/components/UI';
export default defineComponent({
    name: 'Debugger',
    components: {
        Popper,
        Table
    },
    data: () => ({
        popover: null,
        fields: [
            { key: 'key', name: 'Key', sortable: true },
            { key: 'count', name: 'Count', sortable: true }
        ]
    }),
    computed: {
        subscriptions() {
            return this.$store.direct.state.debug.subscriptions;
        },
        items() {
            const subs = [];
            for (const [subkey, sub] of Object.entries(this.subscriptions)) {
                subs.push({ key: subkey, count: sub.count });
            }
            return subs;
        }
    }
});
